import { ShareResourceEnum } from 'modules/share/enum/share-resource-enum'

export const getResourceApiSlug = (
  type?: ShareResourceEnum,
): keyof typeof ShareResourceEnum | undefined => {
  switch (type) {
    case ShareResourceEnum.funnel:
      return 'funnel'
    case ShareResourceEnum.blog:
      return 'blog'
    case ShareResourceEnum.newsletter:
      return 'newsletter'
    case ShareResourceEnum.campaign:
      return 'campaign'
    case ShareResourceEnum.course:
      return 'course'
  }
}
